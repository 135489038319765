<template>
  <v-card width="100%">
    <!-- <v-card-title class="text-h5 mb-2 justify-center" style="background-color: #d9c7A3"
      ><v-spacer />{{ $t('about.title') }} <v-spacer /> <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn></v-card-title
    > -->
    <v-toolbar color="#d9c7A3" class="mb-2">
      <v-spacer />
      <v-toolbar-title>   
        {{ $t('about.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-md-preview :text="text" preview-class="vuepress-markdown-body"></v-md-preview>
    
    </v-card-text>
    <v-img
      :src="require('@/assets/background/birdslife_bottomimage5.jpg')"
      />
  </v-card>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: "testPage",
  data: () => ({
      // text: "# Title of the stuff\n\nthis is the greek text and `what` a thing this is\n\nhahaha a bit complicated indeed **what the** stuff"
  }),
  methods: {
    close () {
      this.$emit("closedialog", false)
    }
  },
  computed: {
    ...get('pages', {
      text: 'page@text'
    })
  },
};
</script>


<style>

.vuepress-markdown-body h2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.vuepress-markdown-body h1 {
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>